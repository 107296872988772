import { useLocation } from 'react-router-dom';
import SearchResultsTable from './SearchResultsTable';
import { useTranslation } from 'react-i18next';

function ResultsComponent() {
    const location = useLocation();
    const results = location.state ? location.state.data : [];

    const { t } = useTranslation();

    return (
        <div className="container mt-5 mb-5">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card shadow">
                        <div className="card-header bg-dark text-white text-center">
                            <h4>{results.length} {t('results')}</h4>
                        </div>
                        <div className="card-body">
                            <SearchResultsTable data={results} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ResultsComponent;


