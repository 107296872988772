import React, { useState } from 'react';
import axios from 'axios';
import './SearchForm.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function SearchForm() {
    console.log("Component rendered")
    const [searchType, setSearchType] = useState('name');
    const [searchValue, setSearchValue] = useState('');
    const [results, setResults] = useState([]);
    const { t } = useTranslation();

    const navigate = useNavigate();

    function handleAxiosError(error) {
        if (error.response) {
            return `Server responded with status code ${error.response.status}: ${error.response.data}`;
        } else if (error.request) {
            return 'No response received from server. Please check server status and network connection.';
        } else {
            return `Request error: ${error.message}`;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://natzweiler-database.eu:5000/search/authorized', { searchType, searchValue });
            setResults(response.data);
            navigate('/results', { state: { data: response.data } });
        } catch (error) {
            console.error(handleAxiosError(error));
        }
    };

    return (
        <div className="container mt-5 mb-5">
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="card shadow">
                        <div className="card-header bg-dark text-white text-center">
                            <h4>{t('researchForm')}</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">{t('searchBy')}</label>
                                    <select value={searchType} onChange={e => setSearchType(e.target.value)} className="form-select">
                                        <option value="name">{t('name')}</option>
                                        <option value="gebo">{t('gebo')}</option>
                                        <option value="stat0">{t('stat0')}</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" placeholder={t('searchPlaceholder')} value={searchValue} onChange={e => setSearchValue(e.target.value)} />
                                </div>
                                <button type="submit" className="btn btn-success w-100">{t('searchPlaceholder')}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchForm;
