// src/components/Header.js

import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <div className="header">
      <h1>NATZWEILER DATABASE</h1>
      <p className="font-italic">„NOUS ÉTIONS 52.000 - WIR WAREN 52.000 – WE WERE 52.000“</p>
      <h1 className="display-4 font-weight-bold">-BETA VERSION-</h1>
    </div>
  );
};

export default Header;
