import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Details.css'
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

function Details() {
    const [data, setData] = useState(null);
    let { id } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        axios.get(`https://natzweiler-database.eu:5000/search/details/${id}`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });

    }, [id]
    );

    function formatKats(kat1, kat2, t) {
        if (kat1 && kat2) {
            return `${kat1} / ${kat2}`;
        }
        else if (kat1 && !kat2) {
            return kat1;
        } else {
        return t('unknown');
        }
    }

    function formatBeruf(beruf1, beruf2, t) {
        if (beruf1 && beruf2) {
            return `${beruf1} / ${beruf2}`;
        }
        else if (beruf1 && !beruf2) {
            return beruf1;
        } else {
        return t('unknown');
        }
    }

    function formatSource(quelle) {
        if (quelle) {
            return <div><p className="card-text text-center">
                {quelle}
            </p>
                <hr />
            </div>
        }
    }

    function formatStation(station, arrived, left) {
        if (station) {
            return <div className="row">
            <div className="col-4">
                <p className="card-text">
                    {formatDate(arrived, t)}
                </p>
            </div>
            <div className="col-4">
                <p className="card-text">
                    {station || t('unknown')}
                </p>
            </div>
            <div className="col-4">
                <p className="card-text">
                    {formatDate(left, t)}
                </p>
            </div>
            <hr />
        </div>
        }
    }

    /*
    function formatTransport(transport1, transport2, t) {
        if (beruf1 && beruf2) {
            return `${beruf1} / ${beruf2}`;
        }
        if (beruf1 && !beruf2) {
            return beruf1;
        }
        return t('unknown');
    }*/

    const formatDate = (dateString, t) => {
        if (dateString) {
            return format(new Date(dateString), 'dd/MM/yyyy');
        } else {
        return t('unknown');
    }
}

    return (
        <div>
            {data ? (

                <div className="container mt-5 mb-5">
                    <nav aria-label="breadcrumb" class="bg-light rounded-3 p-3 mb-4"></nav>
                    <div className='row justify-content-center'>
                        <div className="row">
                            <div className="col-md-5">
                                <div className="card mt-2">
                                    <div className="card-body">
                                        <p className="card-title text-center text-primary">{t('fullName')}</p>
                                        <h3 className="card-title text-center">{data.name}, {data.vname} {data.mname} </h3>
                                        <p className="text-center text-muted">NID {data.nid}</p>
                                        <hr />
                                        <p className="card-text">
                                            <strong>{t("geb")}:</strong> {formatDate(data.geb,t) || t('unknown')}
                                        </p>
                                        <hr />
                                        <p className="card-text">
                                            <strong>{t("gebo")}:</strong> {data.gebo || t('unknown')}
                                        </p>
                                        <hr />
                                        <p className="card-text">
                                            <strong>{t("nat")}:</strong> {data.nat || t('unknown')}
                                        </p>
                                        <hr />
                                        <p className="card-text">
                                            <strong>{t('strbd')}:</strong> {formatDate(data.strbd, t) || t('unknown')}
                                        </p>
                                        <hr />
                                        <p className="card-text">
                                            <strong>{t('strbo')}:</strong> {data.strbo || t('unknown')}
                                        </p>
                                        <hr />
                                        <p className="text-center text-primary">{t('researchedBy')}</p>
                                        <p className="text-center text-muted">{data.researcher}</p>
                                    </div>
                                </div>
                                <div className="card mt-2">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-primary">{t('additional')} </h5>
                                        <hr />
                                        <p className="card-text">
                                            <strong>{t("kat")}:</strong> {formatKats(data.kat, data.kat2)}
                                        </p>
                                        <hr />
                                        <p className="card-text">
                                            <strong>{t("beruf")}:</strong> {formatBeruf(data.beruf, data.beruf1, t)}
                                        </p>
                                        <hr />
                                        <p className="card-text">
                                            <strong>{t("reli")}:</strong> {data.reli || t('unknown')}
                                        </p>
                                    </div>
                                </div>
                                <div className="card mt-2">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-primary">{t('anmSrc')} </h5>
                                        <hr />
                                        <p>{formatSource(data.anm0)}</p>
                                        <p>{formatSource(data.quelle0)}</p>
                                        <p>{formatSource(data.quelle1)}</p>
                                        <p>{formatSource(data.quelle2)}</p>
                                        <p>{formatSource(data.quelle3)}</p>
                                        <p>{formatSource(data.quelle4)}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-7">
                                <img src="https://reactjs.org/logo-og.png" className="img-fluid mt-2 mb-2 rounded" alt="image"/>
                                <div className="card mt-2">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-primary">{t('stations')} </h5>
                                        <hr />
                                        <div className="row">
                                            <div className="col-4">
                                                <p><strong>{t('arrived')}</strong></p>
                                            </div>
                                            <div className="col-4">
                                                <p><strong>{t('stat0')}</strong></p>
                                            </div>
                                            <div className="col-4">
                                                <p><strong>{t('left')}</strong></p>
                                            </div>
                                        </div>
                                        <div>{formatStation(data.stat_2, null, null)}</div>
                                        <div>{formatStation(data.stat_1, null, null)}</div>
                                        <div>{formatStation(data.stat0, data.ank0, data.left0)}</div>
                                        <div>{formatStation(data.stat1, data.ank1, data.left1)}</div>
                                        <div>{formatStation(data.stat2, data.ank2, data.left2)}</div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            ) : (<div>Loading...</div>)}
        </div>
    )
}

export default Details;
