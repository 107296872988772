import Home from './components/Home/Home';
import Header from './components/Header/Header';
import Navbar from './components/Navbar/Navbar';
import About from './components/About/About';
import SearchForm from './components/Search/SearchForm';
import PublicSearchForm from './components/Search/PublicSearchForm';
import ResultsComponent from './components/Results/Results';
import Details from './components/Details/Details';
import Login from './components/User/Login';
import AdminRegister from './components/User/Register';
import ProtectedRoute from './components/User/ProtectedRoute';
import './i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<ProtectedRoute requiredRole="admin"><AdminRegister /></ProtectedRoute>} />
          <Route path="/search" element={<ProtectedRoute><SearchForm /></ProtectedRoute>} />
          <Route path="/publicSearch" element={<PublicSearchForm />} />
          <Route path="/results" element={<ResultsComponent />} />
          <Route path="/about" element={<About />} />
          <Route path="/search/details/:id" element={<Details />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;