import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Flag from './Flag'
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const storedToken = localStorage.getItem('token');

    let decodedToken = null;

    if (storedToken) {
        try {
            decodedToken = jwtDecode(storedToken);
        } catch (e) {
            console.error('Failed to decode token', e);
        }
    }

    const logout = () => {
        localStorage.removeItem('token');

        navigate('/');
    };


    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">Home</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/publicSearch">{t('search')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/search">{t('research')}</Link>
                        </li>
                    </ul>
                </div>
            </div>
            {
                decodedToken
                    ? (
                        decodedToken.roles.includes('user') && decodedToken.roles.includes('admin')
                            ? <div className="d-flex ml-auto"> {/* Both 'user' and 'admin' */}
                                <button className="btn btn-outline-light mr-3" onClick={logout}>{t('logout')}</button> |
                                <Link className="btn btn-outline-light mr-3" to="/register">{t('register')}</Link>
                            </div>
                            : decodedToken.roles.includes('user')
                                ? <div className="d-flex ml-auto"> {/* Only 'user' */}
                                    <button className="btn btn-outline-light mr-3" onClick={logout}>{t('logout')}</button> |
                                </div>
                                : null
                    )
                    : <Link className="btn btn-outline-light mr-3" to="/login">{t('login')}</Link>  /* Not logged in */
            }

            <div style={{ marginRight: '20px', display: 'inline-block' }}></div>
            <div className="d-flex ml-auto">
                <Flag language="de" imgSrc="de.png" altText="Deutsch" onClick={changeLanguage} />
                <Flag language="fr" imgSrc="fr.png" altText="Français" onClick={changeLanguage} />
                <Flag language="en" imgSrc="gb.png" altText="English" onClick={changeLanguage} />
            </div>
        </nav>
    );
};

export default Navbar;
