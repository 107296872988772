import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Login() {
    
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://natzweiler-database.eu:5000/user/login', formData);
            const token = response.data.token;
            const expirationDate = new Date().getTime() + (60 * 60 * 1000);

            localStorage.setItem('token', token);
            localStorage.setItem('tokenExpiration', expirationDate.toString());

            console.log('Login successful!');
            navigate('/search');
        } catch (err) {
            setError(err.response ? err.response.data : 'Something went wrong');
        }
    };

    return (
        <div className="container mt-5 mb-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                <h2>{t('apology')}</h2>
                <hr></hr>
                    <div className="card">
                        <div className="card-header bg-success text-white text-center">Login</div>
                        <div className="card-body">
                            {error && <div className="alert alert-danger">{error}</div>}
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-2">
                                    <label>Username:</label>
                                    <input 
                                        type="username" 
                                        name="username" 
                                        className="form-control"
                                        value={formData.username} 
                                        onChange={handleChange}
                                        required 
                                    />
                                </div>
                                <div className="form-group mb-2">
                                    <label>Password:</label>
                                    <input 
                                        type="password" 
                                        name="password" 
                                        className="form-control"
                                        value={formData.password} 
                                        onChange={handleChange}
                                        required 
                                    />
                                </div>
                                <button type="submit" className="btn btn-success">Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
