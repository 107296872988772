import React, { useMemo } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

function SearchResultsTable({ data }) {

  const { t } = useTranslation();

  const storedToken = localStorage.getItem('token');

  let decodedToken = null;

  if (storedToken) {
    try {
      decodedToken = jwtDecode(storedToken);
    } catch (e) {
      console.error('Failed to decode token', e);
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('vname'),
        accessor: 'vname',
      },
      {
        Header: t('nat'),
        accessor: 'nat',
      },
      {
        Header: t('gebo'),
        accessor: 'gebo',
      },
      {
        Header: t('geb'),
        accessor: 'geb',
        Cell: ({ value }) => formatDate(value)
      },
      {
        Header: t('stat0'),
        accessor: 'stat0',
      },
    ],
    [t]
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useFilters,
    useSortBy
  );

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd/MM/yyyy');
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
  }) {

    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        onClick={e => e.stopPropagation()}
        placeholder={t('searchPlaceholder')}
        className="form-control form-control-sm"
      />
    );
  }

  return (
    <table {...getTableProps()} className="table table-hover table-striped mb-2">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
                <div>{column.canFilter ? column.render('Filter') : null}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>
                  { decodedToken && decodedToken.roles.includes('user') ? (
                  <Link to={{
                    pathname: `/search/details/${cell.row.original._id}`,
                  }}>
                  {cell.render('Cell')}
                  </Link>
                  ) : cell.render('Cell')
                }
                  </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default SearchResultsTable;
