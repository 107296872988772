import React, { useState } from 'react';
import axios from 'axios';

function AdminRegister() {
    console.log("Register Test")
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        roles: 'user' // Default role, you can adjust as needed
    });
    const [error, setError] = useState(null);

    console.log("Is rendered")

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://natzweiler-database.eu:5000/user/register', formData);
            // Upon successful registration, you might want to reset the form or navigate away
            alert('User registered successfully!');
            setFormData({
                username: '',
                email: '',
                password: '',
                roles: 'user'
            });
            console.log('User registered successfully!');
        } catch (err) {
            alert(err.response ? err.response.data : 'Something went wrong');
        }
    };

    return (
        <div className="container mt-5 mb-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header bg-primary text-white">Admin Register</div>
                        <div className="card-body">
                            {error && <div className="alert alert-danger">{error}</div>}
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label>Username:</label>
                                    <input 
                                        type="text" 
                                        name="username" 
                                        className="form-control"
                                        value={formData.username} 
                                        onChange={handleChange}
                                        required 
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Email:</label>
                                    <input 
                                        type="email" 
                                        name="email" 
                                        className="form-control"
                                        value={formData.email} 
                                        onChange={handleChange}
                                        required 
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Password:</label>
                                    <input 
                                        type="password" 
                                        name="password" 
                                        className="form-control"
                                        value={formData.password} 
                                        onChange={handleChange}
                                        required 
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Role:</label>
                                    <select 
                                        name="roles"
                                        className="form-control"
                                        value={formData.roles}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                        {/* Add other roles if necessary */}
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-primary">Register User</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminRegister;
