function Flag({ language, imgSrc, altText, onClick }) {
  return (
    <img
      src={imgSrc}
      alt={altText}
      style={{ cursor: 'pointer', marginRight: '10px' }}
      onClick={() => onClick(language)}
    />
  );
}

export default Flag;