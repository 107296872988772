import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

function ProtectedRoute({ requiredRole, children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    let isTokenValid = false;
    let hasRequiredRole = false;
    

    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp > currentTime) {
        isTokenValid = true;

        if (!requiredRole || (decodedToken.roles && decodedToken.roles.includes(requiredRole))) {
          hasRequiredRole = true;
        }
      }
    }

    if (isTokenValid && hasRequiredRole) {
      setIsAuthenticated(true);
    } else {
      navigate("/login");
    }
  }, [navigate, requiredRole]);

  if (!isAuthenticated) {
    return null;
  } else {
    return children;
  }

  
}

export default ProtectedRoute;
