import React from 'react';
import './Home.css';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
    <div className="row">
      <div className="col-md-3 d-flex flex-column align-items-center mt-5" >
        <img src="LOGO_VGKN.png" alt="Description" className="img-fluid mb-2 " />
        <img src="Natzweiler-Struthof-Memorial-Logo-menu3.png" alt="Description" className="img-fluid mt-5" />
      </div>
      <div className="col-6">
        <hr></hr>
        <p>{t('beta')}</p>
        <hr></hr>
        <div className="mt-5" id="homeText">
          <h1>{t('homeH1')}</h1>
          <h2>{t('homeH2')}</h2>
          <br />
          <p id="homeText">
            {t('homePage')}
          </p>
        </div>
      </div>
      <div className="col-md-3 d-flex flex-column align-items-center mt-5" >
        <img src="LOGO_STRUTHOF.png" alt="Description" className="img-fluid mb-2" />
      </div>
    </div>
</div>
  );
};

export default Home;